import classNames from "classnames";
import React, { ChangeEventHandler, MouseEventHandler } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./custom-date-picker.scss";
import Icon from "../icon/icon";

export interface Props {
  title: string;
  date: Date | null | undefined;
  updateDate: any;
  lastValidDate?: Date;
  name: string;
  hasError?: boolean;
  minDate?: Date;
  showMonthYearPicker?: boolean;
  showYearPicker?: boolean;
  dateFormat?: string;
  excludeDates?: Date[];
  includeDates?: Date[];
  disabled?: boolean;

  filterDates?(date: Date): boolean;
}

function CustomDatePicker(props: Props) {
  const {
    title,
    date,
    updateDate,
    lastValidDate,
    name,
    hasError,
    minDate,
    showYearPicker,
    showMonthYearPicker,
    dateFormat,
    filterDates,
    excludeDates,
    includeDates,
    disabled = false,
  } = props;
  const datePickerClassNames = classNames("custom-date-picker", {
    "has-error": hasError,
  });

  return (
    <div className={datePickerClassNames}>
      <label>
        <span className={"date-picker-label"}>{title}</span>
        <DatePicker
          className="react-datepicker"
          customInput={
            <DateInput dataName={name || "date"} hasError={hasError} />
          }
          selected={date}
          onChange={updateDate}
          minDate={minDate}
          maxDate={lastValidDate}
          showYearPicker={showYearPicker}
          showMonthYearPicker={showMonthYearPicker}
          dateFormat={dateFormat}
          filterDate={filterDates}
          excludeDates={excludeDates}
          includeDates={includeDates}
          disabled={disabled}
        />
      </label>
    </div>
  );
}

export interface DateInputProps {
  dataName: string;
  hasError?: boolean;
  value?: string;
  onClick?: MouseEventHandler;
  onChange?: ChangeEventHandler;
  disabled?: boolean;
}

class DateInput extends React.Component<DateInputProps> {
  render() {
    const { value, onClick, onChange, dataName, hasError, disabled } =
      this.props;
    const calendarIconClassNames = classNames("calendar fa-lg text-primary", {
      "has-error": hasError,
    });

    return (
      <div className="custom-date-picker-input input-group" role="presentation">
        <input
          id={dataName}
          type="text"
          data-testid={`${dataName}-date-picker-input`}
          className="form-control"
          value={value}
          style={{ width: "290px" }}
          onChange={onChange}
          onClick={onClick}
          disabled={disabled}
        />
        <span className={"input-group-addon"} aria-hidden="true">
          <Icon name={calendarIconClassNames} />
        </span>
      </div>
    );
  }
}

export default CustomDatePicker;
