import React, { useEffect, useState } from "react";
import {
  AdjustedDateDirection,
  ScheduledDistributionForRedeferral,
} from "../../../../models/distributions/scheduled-distribution-event";
import { MarketCloseDate } from "../../../../models/market-close-date";
import CustomDatePicker from "../../../../components/custom-date-picker/custom-date-picker";
import Date from "../../../../components/numbers/date";
import {
  formatDateWithoutTimeZone,
  getMonthName,
  getNewAdjustedDate,
  makeDateFromDMY,
} from "../../../../util/date-util";
import { useAppDispatch } from "../../../../store/store";
import { GeneralOption } from "../../../../models/generalOption";
import {
  getAvailableMonths,
  getAvailableYears,
  getDaysForMonth,
  getLastAvailableDayForMonth,
  getMinimumRedeferralDay,
  getMinimumRedeferralMonth,
  getMinimumRedeferralYear,
  getDayFromDate,
  getMonthFromDate,
  getYearFromDate,
} from "../../../../util/redeferral-defined-date-util";
import { getMarketClosedDates } from "../../../../services/enrollment";
import { RedeferralDateUtil } from "../../../../components/redefferal/redeferral-date-input/redeferral-date-util";
import { updateScheduledDistribution } from "../../../../reducers/redeferral/scheduledDistributionForRedeferral";
import { getSessionStorageKey } from "../../../../util/redeferral-util";

interface Props {
  scheduledDistribution: ScheduledDistributionForRedeferral;
  marketCloseDates: MarketCloseDate[];
}

export default function RedeferBeginIndividualPaymentNewDateDisplay(
  props: Props
) {
  const { scheduledDistribution, marketCloseDates } = props;
  const dispatch = useAppDispatch();
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedDay, setSelectedDay] = useState("");
  const [availableYears, setAvailableYears] = useState<number[]>([]);
  const [availableMonths, setAvailableMonths] = useState<number[]>([]);
  const [availableDays, setAvailableDays] = useState<GeneralOption[]>([]);
  const [marketClosedDates, setMarketClosedDates] = useState<string[] | null>(
    null
  );

  const hasPlanDefinedDistribution =
    scheduledDistribution.planDeferralConfig.planDefinedDistribution;
  const planDefinedMonth =
    scheduledDistribution.planDeferralConfig.planDefinedMonth;
  const planDefinedDay =
    scheduledDistribution.planDeferralConfig.planDefinedDay;
  const hidePlanDefinedMonth =
    scheduledDistribution.planDeferralConfig.hideMonth;
  const hidePlanDefinedDay = scheduledDistribution.planDeferralConfig.hideDay;
  const minimumRedeferDate = formatDateWithoutTimeZone(
    scheduledDistribution.minimumRedeferDate,
    "MM/dd/yyyy"
  );
  const adjustDateBack =
    scheduledDistribution.planDeferralConfig.adjustedDateDirection ===
    AdjustedDateDirection.PREVIOUS_DAY;
  const redeferralDateUtil = getRedeferralDateUtil(scheduledDistribution);
  const newDistributionDateYear = scheduledDistribution.newDistributionDate
    ? getYearFromDate(scheduledDistribution.newDistributionDate)
    : null;
  const newDistributionDateMonth = scheduledDistribution.newDistributionDate
    ? getMonthFromDate(scheduledDistribution.newDistributionDate)
    : null;
  const newDistributionDateDay = scheduledDistribution.newDistributionDate
    ? getDayFromDate(scheduledDistribution.newDistributionDate)
    : null;

  useEffect(() => {
    if (newDistributionDateYear) {
      setSelectedYear(newDistributionDateYear.toString());
    }
    if (newDistributionDateMonth !== null) {
      setSelectedMonth(newDistributionDateMonth.toString());
    }
    if (newDistributionDateDay) {
      setSelectedDay(newDistributionDateDay.toString());
    }
  }, []);

  useEffect(() => {
    const year = newDistributionDateYear
      ? newDistributionDateYear.toString()
      : selectedYear;
    const month = newDistributionDateMonth
      ? newDistributionDateMonth.toString()
      : selectedMonth;
    setAvailableYears(
      getAvailableYears(planDefinedMonth, planDefinedDay, minimumRedeferDate)
    );
    setAvailableMonths(
      getAvailableMonths(planDefinedDay, minimumRedeferDate, year)
    );
    setAvailableDays(
      getDaysForMonth(marketClosedDates, minimumRedeferDate, year, month)
    );
    fetchMarketClosedDates();

    async function fetchMarketClosedDates() {
      const mcd = await getMarketClosedDates();
      if (mcd) {
        setMarketClosedDates(mcd);
      }
    }
  }, []);

  function getRedeferralDateUtil(
    scheduledDistribution: ScheduledDistributionForRedeferral
  ) {
    return new RedeferralDateUtil(scheduledDistribution, marketCloseDates);
  }

  const updateData = (
    fieldName: string,
    value: any,
    scheduledDistribution: ScheduledDistributionForRedeferral
  ) => {
    const update = {
      ...scheduledDistribution,
      [fieldName]: value,
    };
    dispatch(updateScheduledDistribution(update));
    sessionStorage.setItem(
      getSessionStorageKey(
        scheduledDistribution.accountId,
        scheduledDistribution.installNum
      ),
      JSON.stringify(update)
    );
    window.dispatchEvent(new Event("sessionUpdate"));
  };

  function handleYearChange(event: React.ChangeEvent<HTMLSelectElement>) {
    if (!planDefinedMonth || !planDefinedDay) {
      updateData("newDistributionDate", null, scheduledDistribution);
    }
    const { value } = event.target;
    setSelectedYear(value);
    setSelectedMonth("");
    setSelectedDay("");
    refreshAvailableDays(value, selectedMonth);
    if (planDefinedMonth && planDefinedDay) {
      let newDistributionDate = "";
      if (marketClosedDates) {
        newDistributionDate = getNewAdjustedDate(
          value,
          selectedMonth,
          selectedDay,
          planDefinedMonth,
          planDefinedDay,
          adjustDateBack,
          marketClosedDates,
          getMinimumDate()
        );
      }
      if (newDistributionDate !== "") {
        updateData(
          "newDistributionDate",
          formatDateWithoutTimeZone(newDistributionDate, "yyyy-MM-dd"),
          scheduledDistribution
        );
      }
    }
  }

  function handleMonthChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;
    setSelectedMonth(value);
    setSelectedDay("");
    refreshAvailableDays(selectedYear, value);
    if (selectedYear) {
      let newDistributionDate = "";
      if (marketClosedDates) {
        const monthOffset = Number(value) + 1;
        const _planDefinedDay = getLastAvailableDayForMonth(
          selectedYear,
          monthOffset,
          planDefinedDay
        );
        newDistributionDate = getNewAdjustedDate(
          selectedYear,
          value,
          selectedDay,
          planDefinedMonth,
          _planDefinedDay,
          adjustDateBack,
          marketClosedDates,
          getMinimumDate()
        );
      }
      if (newDistributionDate !== "") {
        updateData(
          "newDistributionDate",
          formatDateWithoutTimeZone(newDistributionDate, "yyyy-MM-dd"),
          scheduledDistribution
        );
      }
    }
  }

  function handleDayChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;
    setSelectedDay(value);
    if (selectedYear) {
      let newDistributionDate = "";
      if (marketClosedDates) {
        newDistributionDate = getNewAdjustedDate(
          selectedYear,
          selectedMonth,
          value,
          planDefinedMonth,
          planDefinedDay,
          adjustDateBack,
          marketClosedDates,
          getMinimumDate()
        );
      }
      if (newDistributionDate !== "") {
        updateData(
          "newDistributionDate",
          formatDateWithoutTimeZone(newDistributionDate, "yyyy-MM-dd"),
          scheduledDistribution
        );
      }
    }
  }

  function refreshAvailableDays(year: string, month: string) {
    let actualMonth = month;
    if (hasPlanDefinedDistribution) {
      if (planDefinedMonth) {
        actualMonth = (planDefinedMonth - 1).toString();
      }
      setAvailableMonths(
        getAvailableMonths(planDefinedDay, minimumRedeferDate, year)
      );
    }
    setAvailableDays(
      getDaysForMonth(marketClosedDates, minimumRedeferDate, year, actualMonth)
    );
  }

  function getMinimumDate(): Date {
    return makeDateFromDMY(
      getMinimumRedeferralDay(minimumRedeferDate),
      getMinimumRedeferralMonth(minimumRedeferDate),
      getMinimumRedeferralYear(minimumRedeferDate)
    );
  }

  return (
    <>
      {!hasPlanDefinedDistribution && (
        <div data-testid="custom-date-picker">
          <CustomDatePicker
            name={`redeferral-${scheduledDistribution.installNum}`}
            date={
              scheduledDistribution.newDistributionDate
                ? redeferralDateUtil.getDate
                : null
            }
            updateDate={(e: Date) =>
              updateData(
                "newDistributionDate",
                formatDateWithoutTimeZone(e, "yyyy-MM-dd"),
                scheduledDistribution
              )
            }
            hasError={false}
            title=""
            minDate={redeferralDateUtil.minDate}
            filterDates={redeferralDateUtil.filterDates}
            excludeDates={redeferralDateUtil.getMarketCloseDates}
          />
        </div>
      )}
      {hasPlanDefinedDistribution && (
        <>
          <div>
            <label htmlFor="yearInput" className="selectLabel">
              Year
            </label>
            <select
              onChange={handleYearChange}
              className={"form-control"}
              id="yearInput"
              data-testid="yearInput"
              name="year"
              defaultValue={""}
            >
              <option
                value={""}
                disabled={true}
                data-testid="default-year-option"
                selected={"" === selectedYear}
              >
                Select a Year
              </option>
              {availableYears.map((yearNumber) => (
                <option
                  value={yearNumber}
                  data-testid={yearNumber + "-year-option"}
                  selected={yearNumber.toString() === selectedYear}
                >
                  {yearNumber}
                </option>
              ))}
            </select>
          </div>
          <br />
          {planDefinedMonth && (
            <>
              <div>
                {!hidePlanDefinedMonth && (
                  <>
                    <label htmlFor="month-display" className="selectLabel">
                      Month
                    </label>
                    <p id="month-display" data-testid="reveal-defined-month">
                      {getMonthName(planDefinedMonth - 1)}
                    </p>
                  </>
                )}
              </div>
            </>
          )}
          {!planDefinedMonth && (
            <>
              <div>
                <label htmlFor="monthInput" className="selectLabel">
                  Month
                </label>
                <select
                  className={"form-control"}
                  onChange={handleMonthChange}
                  id="monthInput"
                  data-testid="monthInput"
                  name="month"
                  defaultValue={""}
                >
                  <option
                    value={""}
                    disabled={true}
                    data-testid={"default-month-option"}
                    selected={"" === selectedMonth}
                  >
                    Select a Month
                  </option>
                  {availableMonths.map((monthNumber) => (
                    <option
                      value={monthNumber}
                      data-testid={monthNumber + "-month-option"}
                      selected={monthNumber.toString() === selectedMonth}
                    >
                      {getMonthName(monthNumber)}
                    </option>
                  ))}
                </select>
              </div>
            </>
          )}
          {planDefinedDay && (
            <>
              <div>
                {!hidePlanDefinedDay && (
                  <>
                    <label htmlFor="day-display" className="selectLabel">
                      Day
                    </label>
                    <p id="day-display" data-testid="reveal-defined-day">
                      {planDefinedDay}
                    </p>
                  </>
                )}
              </div>
            </>
          )}
          {!planDefinedDay && (
            <>
              <div>
                <label htmlFor="dayInput" className="selectLabel">
                  Day
                </label>
                <select
                  onChange={handleDayChange}
                  className={"form-control"}
                  id="dayInput"
                  data-testid="dayInput"
                  name="day"
                  defaultValue={""}
                >
                  <option
                    value={""}
                    disabled={true}
                    data-testid={"default-day-option"}
                    selected={"" === selectedDay}
                  >
                    Select a Day
                  </option>
                  {availableDays.map((dayNumber) => (
                    <option
                      value={dayNumber.value}
                      disabled={dayNumber.disabled}
                      data-testid={dayNumber.value + "-day-option"}
                      selected={dayNumber.value.toString() === selectedDay}
                    >
                      {dayNumber.name}
                    </option>
                  ))}
                </select>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
