import React from "react";
import { getStartOfYear, subtractYears } from "../../util/date-util";
import CustomDatePicker from "../../components/custom-date-picker/custom-date-picker";

const StatementDate = (props) => {
  const { startDate, setStartDate, endDate, setEndDate, lastBusinessDate } =
    props;
  const minDate = getStartOfYear(subtractYears(new Date(), 2));

  return (
    <div>
      <CustomDatePicker
        // hasError={startDateIsInvalid}
        title="Start Date"
        date={startDate}
        name={"start"}
        minDate={minDate}
        updateDate={setStartDate}
        lastValidDate={endDate}
      />
      <CustomDatePicker
        // hasError={startDateIsInvalid}
        title="End Date"
        date={endDate}
        minDate={startDate}
        name={"end"}
        updateDate={setEndDate}
        lastValidDate={lastBusinessDate}
      />

      <span>
        Please note: on-demand statements are limited to account information
        from within the last two full years.
      </span>
    </div>
  );
};

export default StatementDate;
