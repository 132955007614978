import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/cjs/Button";
import BSModal from "react-bootstrap/Modal";
import { Account } from "../../../models/balances-by-source/account";
import CustomDatePicker from "../../../components/custom-date-picker/custom-date-picker";
import AccountName from "../../../components/account-name/account-name";

type beneModalProps = {
  showChangeViewModal: boolean;
  accounts: Account[];
  selectedAccounts: Account[];
  hide: () => void;
  initialAsOfDate: Date;
  asOfDate: Date;
  setAsOfDate: (date: Date) => void;
  setSelectedAccounts: (accounts: Account[]) => void;
};

export default function ChangeView(props: beneModalProps) {
  const {
    showChangeViewModal,
    accounts,
    selectedAccounts,
    setSelectedAccounts,
    hide,
    initialAsOfDate,
    setAsOfDate,
    asOfDate,
  } = props;
  const [modalSelectedAccounts, setModalSelectedAccounts] =
    useState(selectedAccounts);
  const [modalAsOfDate, setModalAsOfDate] = useState(asOfDate);

  useEffect(() => {
    if (selectedAccounts.length === 0) {
      setModalSelectedAccounts(accounts);
    }
  }, []);

  const maxDate = asOfDate;
  const minDate = (date: Date) => {
    date.setMonth(date.getMonth() - 24);
    return date;
  };

  const continueClicked = () => {
    setAsOfDate(modalAsOfDate);
    setSelectedAccounts(modalSelectedAccounts);
    hide();
  };

  const allAccountsSelected = accounts.length === modalSelectedAccounts.length;

  function isChecked(account: Account) {
    return Boolean(
      modalSelectedAccounts.find(
        (selectedAccount) => selectedAccount.accountId === account.accountId
      )
    );
  }

  function toggleAll(e: React.ChangeEvent<HTMLInputElement>) {
    const { checked } = e.target;
    checked ? setModalSelectedAccounts(accounts) : setModalSelectedAccounts([]);
  }

  function handleChange(
    e: React.ChangeEvent<HTMLInputElement>,
    account: Account
  ) {
    const { checked } = e.target;
    if (checked) {
      setModalSelectedAccounts(addSelectedAccount(account));
    } else {
      const newAccounts = removeSelectedAccount(account);
      setModalSelectedAccounts(newAccounts);
    }
  }

  function removeSelectedAccount(account: Account) {
    return modalSelectedAccounts.filter(
      (selectedAccount) => selectedAccount.accountId !== account.accountId
    );
  }

  function addSelectedAccount(account: Account) {
    return [...modalSelectedAccounts, account];
  }

  return (
    <BSModal
      show={showChangeViewModal}
      backdropClassName="modal-backdrop"
      animation={false}
      onHide={hide}
      size="lg"
    >
      <BSModal.Body>
        <div className="util-margin-top-15">
          <span style={{ color: "#003366", fontWeight: "bold" }}>
            Change As of Date
          </span>
          <div className="date-picker">
            <CustomDatePicker
              title=""
              date={modalAsOfDate}
              name="as of date"
              minDate={minDate(new Date(initialAsOfDate))}
              hasError={false}
              updateDate={setModalAsOfDate}
              lastValidDate={initialAsOfDate}
            />
          </div>
          <span style={{ fontStyle: "italic" }}>
            The available time period is the prior 24 months.
          </span>
        </div>
        <div className="util-margin-top-20">
          <span style={{ color: "#003366", fontWeight: "bold" }}>
            Change Accounts
          </span>
          <div>
            <table data-testid="personal-info-table" className="table">
              <tbody>
                <tr style={{ background: "#caebfa", fontWeight: "bold" }}>
                  <td colSpan={1} className="account-filter table-head">
                    <span>
                      <input
                        className="custom-checkbox"
                        type="checkbox"
                        checked={allAccountsSelected}
                        onChange={toggleAll}
                      />
                    </span>

                    <span>Select All/None </span>
                  </td>
                  <td colSpan={1} className="table-head">
                    View All Accounts
                  </td>
                </tr>
                {accounts.map((account) => {
                  return (
                    <tr>
                      <td className="account-filter">
                        <input
                          className="custom-checkbox"
                          type="checkbox"
                          checked={isChecked(account)}
                          onChange={(e) => handleChange(e, account)}
                        />
                      </td>
                      <td>
                        <div>
                          <AccountName
                            name={account.accountName}
                            id={account.accountId}
                            asLink={false}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </BSModal.Body>
      <BSModal.Footer>
        <button className="btn btn-link" type="button" onClick={() => hide()}>
          Close
        </button>
        <Button onClick={() => continueClicked()}>Continue</Button>
      </BSModal.Footer>
    </BSModal>
  );
}
