import _sumBy from "lodash/sumBy";
import React from "react";
import InvestmentAdvisorLink from "../../../../components/investments/investment-advisor-link";
import InvestmentLink from "../../../../components/investments/investment-link";
import Balance from "../../../../components/numbers/balance";
import FixedDecimal from "../../../../components/numbers/fixed-decimal";
import Percent from "../../../../components/numbers/percent";
import Share from "../../../../components/numbers/share";
import { Skeleton } from "../../../../components/skeleton/skeleton";
import {
  accountsStatusSelector,
  datesStatusSelector,
  investmentsStatusSelector,
} from "../../../../selectors/status";
import {
  calculateTotalPRR,
  calculateTotalRateOfReturn,
} from "../../../../util/investments";

export function buildInvestmentOptionColumn(hasFooter = true) {
  return {
    Header: <InvestmentAdvisorLink />,
    accessor: "id",
    className: "left",
    Cell: ({ row }: any) => (
      <>
        <em data-testid={`institutionName-${row.original.id}`} className="show">
          <Skeleton
            selectors={[
              investmentsStatusSelector,
              accountsStatusSelector,
              datesStatusSelector,
            ]}
            height={18}
            style={{ margin: "3px 0" }}
          >
            {row.original.institutionName}
          </Skeleton>
        </em>
        <Skeleton
          selectors={[
            investmentsStatusSelector,
            accountsStatusSelector,
            datesStatusSelector,
          ]}
          height={18}
          style={{ margin: "3px 0" }}
        >
          <InvestmentLink
            id={row.original.id}
            fundName={row.original.name}
            className="link"
          />
        </Skeleton>
      </>
    ),
    Footer: hasFooter && <div className="bold">Total</div>,
  };
}

export function buildCurrentBalanceColumn() {
  return {
    Header: <span className="bold">Current Balance</span>,
    accessor: "endBalance.cashBalance.balance",
    className: "right",
    Cell: ({ value }: any) => (
      <Skeleton
        selectors={[
          investmentsStatusSelector,
          accountsStatusSelector,
          datesStatusSelector,
        ]}
        height={18}
        width={90}
        style={{ margin: "3px 0" }}
      >
        <Balance value={value} />{" "}
      </Skeleton>
    ),
    Footer: (info: any) => {
      const total = React.useMemo(
        () => _sumBy(info.rows, `original.endBalance.cashBalance.balance`),
        [info.rows]
      );
      return (
        <div className="bold">
          <Skeleton
            selectors={[
              investmentsStatusSelector,
              accountsStatusSelector,
              datesStatusSelector,
            ]}
            height={18}
            width={90}
            style={{ margin: "3px 0" }}
          >
            $<Balance value={total} />
          </Skeleton>
        </div>
      );
    },
  };
}

export function buildBalancePercentColumn(totalAccountBalance: any = 1) {
  return {
    Header: (
      <span className="bold">
        <span className="show">Percent of Total </span>Current Balance
      </span>
    ),
    id: "balanceRatio",
    accessor: "endBalance.cashBalance.balance",
    className: "right",
    Cell: ({ value }: any) => {
      return (
        <Skeleton
          selectors={[
            investmentsStatusSelector,
            accountsStatusSelector,
            datesStatusSelector,
          ]}
          height={18}
          width={90}
          style={{ margin: "3px 0" }}
        >
          <FixedDecimal
            value={(100 * value) / totalAccountBalance}
            precision={2}
          />
        </Skeleton>
      );
    },
    Footer: (
      <Skeleton
        selectors={[
          investmentsStatusSelector,
          accountsStatusSelector,
          datesStatusSelector,
        ]}
        height={18}
        width={90}
        style={{ margin: "3px 0" }}
      >
        <div className="bold">100.00%</div>
      </Skeleton>
    ),
  };
}

export function buildTotalSharesColumn() {
  return {
    Header: <div className="bold">Shares/Units</div>,
    accessor: "endBalance.shareBalance.totalBalance",
    className: "right",
    Cell: ({ value }: any) => (
      <Skeleton
        selectors={[
          investmentsStatusSelector,
          accountsStatusSelector,
          datesStatusSelector,
        ]}
        height={18}
        width={90}
        style={{ margin: "3px 0" }}
      >
        <Share value={value} />
      </Skeleton>
    ),
    Footer: () => {
      return <div className="bold">N/A</div>;
    },
  };
}

export function buildSharesColumn() {
  return {
    Header: (
      <div className="bold">
        <span className="show">Vested</span>
        Shares/Units
      </div>
    ),
    accessor: "endBalance.shareBalance.balance",
    className: "right",
    Cell: ({ value }: any) => (
      <Skeleton
        selectors={[
          investmentsStatusSelector,
          accountsStatusSelector,
          datesStatusSelector,
        ]}
        height={18}
        width={90}
        style={{ margin: "3px 0" }}
      >
        <Share value={value} />
      </Skeleton>
    ),
    Footer: () => {
      return <div className="bold">N/A</div>;
    },
  };
}

export function buildPrrAmtColumn(hideLink = false, useLiabilityRest = false) {
  return {
    Header: (
      <div className="bold">
        Personalized
        <span className="show">Rate of Return</span>
        {hideLink ? (
          <></>
        ) : (
          <sup>
            [<a href="#[1]">1</a>]
          </sup>
        )}
        <span className="text-enter show">(%)</span>
      </div>
    ),
    accessor: "prrAmt",
    className: "right",
    Cell: ({ value, row }: any) => (
      <Skeleton
        selectors={[
          investmentsStatusSelector,
          accountsStatusSelector,
          datesStatusSelector,
        ]}
        height={18}
        style={{ margin: "3px 0" }}
      >
        {row?.original?.endBalance?.cashBalance?.balance ? (
          <Percent value={value} isPercentage={false} showSymbol={false} />
        ) : (
          <span>N/A</span>
        )}
      </Skeleton>
    ),
    Footer: (info: any) => {
      const investments = info.rows.map((row: any) => row.original);
      const total = React.useMemo(
        () =>
          useLiabilityRest
            ? calculateTotalPRR(investments)
            : calculateTotalRateOfReturn(investments),
        [investments]
      );
      return (
        <div className="bold">
          <Skeleton
            selectors={[
              investmentsStatusSelector,
              accountsStatusSelector,
              datesStatusSelector,
            ]}
            height={18}
            style={{ margin: "3px 0" }}
          >
            <Percent value={total} />
          </Skeleton>
        </div>
      );
    },
  };
}
