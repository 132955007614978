import { parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import BSModal from "react-bootstrap/Modal";
import { Skeleton } from "../../skeleton/skeleton";
import { Account } from "../../../models/account";
import { setSelectedAccounts } from "../../../reducers/accounts";
import { useDates } from "../../../selectors/dates";
import {
  accountsStatusSelector,
  datesStatusSelector,
} from "../../../selectors/status";
import { useAppDispatch, useSelector } from "../../../store/store";
import {
  isAfter,
  isSame,
  parseISOStringIntoDate,
  subtractYears,
  isBefore,
  isSameOrBefore,
} from "../../../util/date-util";
import AccountFilter from "./account-filter";
import CustomDatePicker from "../../custom-date-picker/custom-date-picker";
import "./filter-investments-modal.scss";
import StartDateErrorMessage from "./start-date-error-message";
import { setInvestmentSummaryDates } from "../../../reducers/dates";
import { statementBalanceSelector } from "src/selectors/statement-balance";

export default function FilterInvestmentsModal() {
  const dispatch = useAppDispatch();

  const { available: accounts, selected: selectedAccounts } = useSelector(
    (state) => state.accounts
  );
  const [show, setShow] = useState(false);

  function showModal() {
    setShow(true);
  }

  function hideModal() {
    setShow(false);
  }

  const { startDate, endDate, lastBusinessDate } = useDates();
  const [tempSelectedAccounts, setTempSelectedAccounts] =
    useState<Account[]>(selectedAccounts);
  const [tempStartDate, setTempStartDate] = useState<Date>();
  const [tempEndDate, setTempEndDate] = useState<Date>();
  const noSelectedAccounts = tempSelectedAccounts.length === 0;
  const statementBalanceData = useSelector(statementBalanceSelector);
  const [minSelectableDate, setMinSelectableDate] = useState("");

  const getStart = function (): Date {
    if (minSelectableDate?.length && tempStartDate) {
      const dateIsValid = isSameOrBefore(minSelectableDate, tempStartDate);

      if (!dateIsValid) {
        setTempStartDate(new Date(minSelectableDate));
        return new Date(minSelectableDate);
      }

      return tempStartDate;
    }

    return parseISOStringIntoDate(startDate!);
  };

  const getEnd = function (): Date {
    return tempEndDate ? tempEndDate : parseISOStringIntoDate(endDate!);
  };

  const startDateIsInvalid = isAfter(getStart(), getEnd());

  function handleModalClose() {
    if (
      !isSame(parseISO(startDate!), tempStartDate) ||
      !isSame(parseISO(endDate!), tempEndDate)
    ) {
      const start = tempStartDate
        ? parseISO(getNeutralISOString(tempStartDate)).toISOString()
        : startDate!;
      const end = tempEndDate
        ? parseISO(getNeutralISOString(tempEndDate)).toISOString()
        : endDate!;
      dispatch(
        setInvestmentSummaryDates({
          investmentSummaryStartDate: start,
          investmentSummaryEndDate: end,
        })
      );
    }
    const accountIds = tempSelectedAccounts.map((account) => account.id);
    dispatch(setSelectedAccounts(accountIds));
    hideModal();
  }

  function getNeutralISOString(inputDate: Date) {
    const inputMonth =
      inputDate.getMonth() < 9
        ? `0${inputDate.getMonth() + 1}`
        : inputDate.getMonth() + 1;
    const inputDay =
      inputDate.getDate() < 10
        ? `0${inputDate.getDate()}`
        : inputDate.getDate();
    return `${inputDate.getFullYear()}-${inputMonth}-${inputDay}T00:00:00.000Z`;
  }

  useEffect(() => {
    setTempSelectedAccounts(selectedAccounts);
  }, [selectedAccounts]);

  function convertDateFormat(dateString: any) {
    const [year, month, day] = dateString.split("-");
    return `${month}/${day}/${year}`;
  }

  useEffect(() => {
    let initialBalanceDate = "";

    if (
      statementBalanceData &&
      statementBalanceData.accountPrrs &&
      statementBalanceData.accountPrrs.length > 0 &&
      tempSelectedAccounts.length > 0
    ) {
      const accountIds = tempSelectedAccounts.map((account) => account.id);
      const oldestSelectedAccount = statementBalanceData.accountPrrs
        .filter(({ accountId }) => accountIds.includes(parseInt(accountId)))
        .sort((a, b) => {
          if (isAfter(a.initialBalanceDate, b.initialBalanceDate)) {
            return 1;
          } else {
            return -1;
          }
        })[0];
      const formattedDate = convertDateFormat(
        oldestSelectedAccount.initialBalanceDate
      );
      initialBalanceDate = formattedDate;
    }

    setMinSelectableDate(startDate!);

    if (initialBalanceDate.length) {
      const initialBalanceDateIso = new Date(initialBalanceDate).toISOString();
      const now = new Date();
      const twoYearsAgo = subtractYears(now, 2).toISOString();

      // start date is always start of the year
      // if the begin date is after the start of the year,
      // don't let user select before it
      if (isAfter(initialBalanceDateIso, startDate!)) {
        setMinSelectableDate(initialBalanceDateIso);
      }

      // if the account was initialy funded over two years ago,
      // we don't have data so set min date to 2 years ago
      else if (isBefore(initialBalanceDateIso, twoYearsAgo)) {
        setMinSelectableDate(twoYearsAgo);
      }

      // initially funded less than two years ago but before the start of the year
      else if (isBefore(initialBalanceDateIso, startDate!)) {
        setMinSelectableDate(initialBalanceDateIso);
      }
    }
  }, [tempSelectedAccounts, statementBalanceData, startDate]);

  return (
    <div className="filter-investments-modal">
      <Skeleton
        selectors={[accountsStatusSelector, datesStatusSelector]}
        height={24}
        width={100}
      >
        <button className="modal-link" onClickCapture={showModal}>
          <span>Change view</span>
        </button>
      </Skeleton>
      <BSModal
        show={show}
        size="lg"
        backdropClassName="modal-backdrop"
        animation={false}
        onHide={hideModal}
      >
        <BSModal.Header closeButton>
          <BSModal.Title as={"h2"}>Filter Investment Summary</BSModal.Title>
        </BSModal.Header>
        <BSModal.Body>
          {startDateIsInvalid && <StartDateErrorMessage />}
          <h3>Change Dates</h3>
          <div className="row">
            <div className="col-xs-6">
              <CustomDatePicker
                hasError={startDateIsInvalid}
                title="Start Date"
                date={getStart()}
                name={"start"}
                minDate={
                  minSelectableDate?.length
                    ? new Date(minSelectableDate)
                    : undefined
                }
                updateDate={(
                  date: Date,
                  e: React.ChangeEvent<HTMLInputElement>
                ) => {
                  e.preventDefault();
                  setTempStartDate(date);
                }}
                lastValidDate={parseISO(lastBusinessDate!)}
              />
            </div>
            <div className="col-xs-6">
              <CustomDatePicker
                hasError={startDateIsInvalid}
                title="End Date"
                date={getEnd()}
                name={"end"}
                minDate={
                  minSelectableDate?.length
                    ? new Date(minSelectableDate)
                    : undefined
                }
                updateDate={(
                  date: Date,
                  e: React.ChangeEvent<HTMLInputElement>
                ) => {
                  e.preventDefault();
                  setTempEndDate(date);
                }}
                lastValidDate={parseISO(lastBusinessDate!)}
              />
            </div>
          </div>
          <AccountFilter
            accounts={accounts}
            selectedAccounts={tempSelectedAccounts}
            setSelectedAccounts={setTempSelectedAccounts}
          />
        </BSModal.Body>
        <BSModal.Footer>
          <ModalFooter
            startDateIsInvalid={startDateIsInvalid}
            noSelectedAccounts={noSelectedAccounts}
            handleClick={handleModalClose}
            hideModal={hideModal}
          />
        </BSModal.Footer>
      </BSModal>
    </div>
  );
}

type FooterProps = {
  startDateIsInvalid: boolean;
  noSelectedAccounts: boolean;
  handleClick: () => void;
  hideModal: () => void;
};

function ModalFooter(props: FooterProps) {
  return (
    <>
      <button
        type="button"
        className="btn cancel-button"
        onClick={props.hideModal}
      >
        Cancel
      </button>
      <button
        className="btn btn-primary"
        data-testid="continue-button"
        disabled={props.startDateIsInvalid || props.noSelectedAccounts}
        data-dismiss="modal"
        onClick={props.handleClick}
      >
        Continue
      </button>
    </>
  );
}
