import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import LoadingSpinner from "../../../components/loading-spinner/loading-spinner";
import { TaxImplication409aModal } from "../../../components/modal/tax-implication-modals/tax-implication-409a-modal";
import { TaxImplication457bModal } from "../../../components/modal/tax-implication-modals/tax-implication-457b-modal";
import { UnscheduledDistributionEvent } from "../../../models/distributions/unscheduled-distribution-event";
import { PlanCode } from "../../../models/plan/plan-info-model";
import { fetchForecastDistributionsResource } from "../../../reducers/distributions/forecast-distributions-resource";
import { fetchForecastDistributionsResourceFromGraphClick } from "../../../reducers/distributions/forecast-distributions-resource-graph";
import { fetchDistributionForecastingReport } from "../../../reducers/reports/distribution-forecasting-report";
import { accountLevelElectionsSelector } from "../../../selectors/account-distributions";
import {
  forecastDistributionResource,
  forecastDistributionResourceFromGraph,
} from "../../../selectors/distribution-forecast";
import { participantLevelElectionsSelector } from "../../../selectors/estimated-distributions";
import {
  accountLevelElectionsStatusSelector,
  forecastDistributionsResourceFromGraphStatus,
  forecastDistributionsResourceStatus,
  participantLevelElectionsStatus,
  unscheduledDistributionEventsByProvisionStatus,
  unscheduledDistributionEventsStatus,
  useStatuses,
} from "../../../selectors/status";
import { unscheduledDistributionEventsForAllProvisionsSelector } from "../../../selectors/unscheduled-distribution-events";
import { useSelector } from "../../../store/store";
import { currencyFormatter } from "../../../util/currency";
import { addYears } from "../../../util/date-util";
import CustomDatePicker from "../../../components/custom-date-picker/custom-date-picker";
import { TabChangeFunction } from "../distribution-summary/distribution-details";
import { DistributionForecastingGraph } from "./distribution-forecasting-graph";
import { DistributionsNotIncludedTable } from "./distributions-not-included-table";
import { EstimatedDistributionsOccurringTable } from "./estimated-distributions-occurring-table";

export interface DistributionForecastingProps {
  changeTabs: TabChangeFunction;
}

export function DistributionForecasting(props: DistributionForecastingProps) {
  const dispatch = useDispatch();
  const planCode = useSelector((state) => state.plan.info.planCode);
  const { employerContactPhoneNumber } = useSelector(
    (state) => state.plan.info
  );
  const accountLevelElections = useSelector(accountLevelElectionsSelector);
  const participantLevelElections = useSelector(
    participantLevelElectionsSelector
  );
  const unscheduledDistributionEvents = useSelector(
    unscheduledDistributionEventsForAllProvisionsSelector
  );
  const forecastDistributionsResource = useSelector(
    forecastDistributionResource
  );
  const forecastDistributionsResourceFromGraph = useSelector(
    forecastDistributionResourceFromGraph
  );
  const [forecastDistributions, setForecastDistributions] = useState(
    forecastDistributionsResource
  );
  const { planId, empId, role } = useSelector((state) => state.session);
  const [unscheduledDistributionEventsWithElections] = useState<
    UnscheduledDistributionEvent[]
  >([]);
  const [eventName, setEventName] = useState("");
  const [rateOfReturn, setRateOfReturn] = useState(0);
  const [forecastDate, setForecastDate] = useState("");
  const [date, setDate] = useState(new Date());
  const [forecastYear, setForecastYear] = useState("forecast year");
  const [hasErrors, setHasErrors] = useState(false);
  const [calculateButtonClicked, setCalculateButtonClicked] = useState(false);
  const todayDate: Date = new Date();
  const maxDate: Date = addYears(todayDate, 50);
  const loadSelectors = [
    accountLevelElectionsStatusSelector,
    participantLevelElectionsStatus,
    unscheduledDistributionEventsStatus,
    unscheduledDistributionEventsByProvisionStatus,
  ];

  const isExcess = planCode === "EXCESS";
  const is457b = planCode.toLowerCase() === PlanCode.type457b;
  const { isLoadingStatus } = useStatuses(loadSelectors);
  const { isOnlyLoadingStatus } = useStatuses([
    forecastDistributionsResourceStatus,
    forecastDistributionsResourceFromGraphStatus,
  ]);

  const getDistributionForecastingReport = () => {
    dispatch(
      fetchDistributionForecastingReport({
        planId,
        empId,
        role,
        eventName,
        forecastDate,
        rateOfReturn,
      })
    );
  };

  useEffect(() => {
    if (unscheduledDistributionEventsWithElections.length === 0) {
      createUnscheduledDistributionEventsWithElectionsArray();
    }
  }, [isLoadingStatus]);

  useEffect(() => {
    setForecastDistributions(forecastDistributionsResource);
  }, [forecastDistributionsResource]);

  useEffect(() => {
    setForecastDistributions(forecastDistributionsResourceFromGraph);
  }, [forecastDistributionsResourceFromGraph]);

  function createUnscheduledDistributionEventsWithElectionsArray() {
    const eventIdsEventsCreatedFor: number[] = [];
    accountLevelElections.forEach((account) => {
      unscheduledDistributionEvents.forEach((event) => {
        if (
          account.eventId === event.id &&
          !eventIdsEventsCreatedFor.includes(event.id)
        ) {
          unscheduledDistributionEventsWithElections.push(event);
          eventIdsEventsCreatedFor.push(event.id);
        }
      });
    });
    participantLevelElections.forEach((account) => {
      unscheduledDistributionEvents.forEach((event) => {
        if (
          account.eventId === event.id &&
          !eventIdsEventsCreatedFor.includes(event.id)
        ) {
          unscheduledDistributionEventsWithElections.push(event);
          eventIdsEventsCreatedFor.push(event.id);
        }
      });
    });
  }

  function handleSelectChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;
    setEventName(value);
  }

  function handleChange(value: React.SetStateAction<string>) {
    setRateOfReturn(Number(value));
  }

  function isInvalid(): boolean {
    if (
      rateOfReturn < 0 ||
      rateOfReturn > 12 ||
      !Number.isInteger(rateOfReturn)
    ) {
      setHasErrors(true);
      return true;
    }
    return false;
  }

  function calculate(isGraphClick: boolean, selectedYear: string = "") {
    if (!isInvalid()) {
      setHasErrors(false);
      const month = (date.getMonth() + 1).toString();
      const day = date.getDate().toString();
      const year = date.getFullYear().toString();
      const forecastDate = month + "-" + day + "-" + year;
      const forecastYear = selectedYear ? selectedYear : year;
      setForecastYear(forecastYear);
      setForecastDate(forecastDate);
      setCalculateButtonClicked(true);
      if (isGraphClick) {
        dispatch(
          fetchForecastDistributionsResourceFromGraphClick({
            planId,
            empId,
            role,
            eventName,
            forecastDate,
            selectedYear,
            rateOfReturn,
          })
        );
      } else {
        dispatch(
          fetchForecastDistributionsResource({
            planId,
            empId,
            role,
            eventName,
            forecastDate,
            rateOfReturn,
          })
        );
      }
    }
  }

  if (
    unscheduledDistributionEventsWithElections &&
    unscheduledDistributionEventsWithElections.length === 0
  ) {
    return (
      <>
        <div className="row">
          <div className="col-md-12">
            <p>
              You do not have any available distribution elections to forecast.
            </p>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="row bg-white util-padding-15">
        <div className="col-md-4 bg-light-blue">
          <h2>Forecast your distributions</h2>
          <p>
            Input your assumptions below to estimate your future pre-tax
            distribution.
          </p>
          <div className="form-group">
            <label htmlFor="percentage">Estimated Rate of Return</label>
            <span>
              {" "}
              <sup>
                [
                <a href="#[1]">
                  <span className="sr-only">Footnote&nbsp;</span>1
                </a>
                ]
              </sup>
            </span>
            <div className="input-suffix">
              <input
                className="form-control"
                id="percentage"
                type="number"
                defaultValue={0}
                onChange={(e) => handleChange(e.target.value)}
              />
              <span className="suffix">%</span>
            </div>
            {hasErrors && (
              <div className="red">
                <i className="fa fa-exclamation-triangle" />
                The rate of return must be a whole number between 0-12.
              </div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="usdeSelect">Distribution Event</label>
            <select
              id="usdeSelect"
              className="form-control"
              data-testid="usdeSelect"
              onChange={handleSelectChange}
            >
              <option hidden key="default">
                Select an option...
              </option>
              {unscheduledDistributionEventsWithElections.map((event) => {
                return (
                  <option value={event.name} key={event.id}>
                    {event.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="date-picker form-group">
            <CustomDatePicker
              title="Date of Event"
              date={date}
              name="retirement"
              minDate={todayDate}
              hasError={false}
              updateDate={setDate}
              lastValidDate={maxDate}
            />
          </div>
          {!calculateButtonClicked && (
            <button
              disabled={eventName === ""}
              className="btn btn-primary form-group"
              style={{ marginLeft: "100px" }}
              onClick={() => calculate(false)}
            >
              Calculate
            </button>
          )}
          {calculateButtonClicked && (
            <>
              <button
                className="btn btn-primary form-group"
                style={{ marginLeft: "14px" }}
                onClick={() => calculate(false)}
              >
                Calculate
              </button>
              &nbsp;&nbsp;
              <button
                className="btn btn-primary form-group"
                onClick={() => getDistributionForecastingReport()}
              >
                Download Forecast
              </button>
            </>
          )}
          <br />
          <br />
        </div>

        <div className="col-md-8">
          <DistributionForecastingGraph
            forecastDistributions={
              forecastDistributionsResource.forecastDistributions
            }
            forecastDistributionsForGraph={
              forecastDistributionsResource.forecastDistributionsForGraph
            }
            calculateFunction={calculate}
            changeTabs={props.changeTabs}
          />
        </div>
      </div>

      <div className="col-md-16">
        <div>
          <h2>Estimated distributions occurring in {forecastYear}</h2>
          {isExcess && (
            <>
              <p>
                Remember that these forecasted distribution amounts are just
                estimations and are not a guarantee of any distribution amount.
                Upon a distribution event, your distribution will be subject to
                income tax. For more details, see
                <TaxImplication409aModal
                  infoIcon={false}
                  linkText={"this information."}
                />
                Prior distributions are not included in this calculation and can
                be viewed in
                <a
                  onClick={(event) => {
                    props.changeTabs(event, "distribution-history-tab");
                  }}
                >
                  {" "}
                  Distribution History.
                </a>
              </p>
            </>
          )}
          {is457b && (
            <>
              <p>
                Remember that these forecasted distribution amounts are just
                estimations and are not a guarantee of any distribution amount.
                Upon a distribution event, your distribution will be subject to
                income tax. For more details, see
                <TaxImplication457bModal
                  infoIcon={false}
                  linkText={"this information."}
                />
                Prior distributions are not included in this calculation and can
                be viewed in
                <a
                  onClick={(event) => {
                    props.changeTabs(event, "distribution-history-tab");
                  }}
                >
                  {" "}
                  Distribution History.
                </a>
              </p>
            </>
          )}
          {!(isExcess || is457b) && (
            <p>
              Remember that these forecasted distribution amounts are just
              estimations and are not a guarantee of any distribution amount.
              Prior distributions are not included in this calculation and can
              be viewed in
              <a
                onClick={(event) => {
                  props.changeTabs(event, "distribution-history-tab");
                }}
              >
                {" "}
                Distribution History.
              </a>
            </p>
          )}

          <table className="table">
            <tbody>
              {isOnlyLoadingStatus ? (
                <LoadingSpinner />
              ) : (
                <>
                  <tr>
                    <td className="text-bold">Account</td>
                    <td className="text-bold">Current Amount</td>
                    <td className="text-bold">Estimated Distribution</td>
                  </tr>
                  {forecastDistributions.forecastDistributionRolledUp.length >
                  0 ? (
                    forecastDistributions.forecastDistributionRolledUp.map(
                      (x) => {
                        return (
                          <EstimatedDistributionsOccurringTable
                            forecastDistributionRolledUp={x}
                            forecastDistributions={forecastDistributions}
                            forecastYear={forecastYear}
                            rateOfReturn={rateOfReturn}
                          />
                        );
                      }
                    )
                  ) : (
                    <>
                      <p>No Distributions for this Selection</p>
                    </>
                  )}
                  {forecastDistributions.forecastDistributionRolledUp.length >
                  0 ? (
                    <>
                      <tr>
                        <td>
                          <b>Distribution Amount Occurring in {forecastYear}</b>
                        </td>
                        <td></td>
                        <td>
                          <b>
                            {currencyFormatter(
                              forecastDistributions.forecastDistributionRolledUp
                                .map((x) => x.estimatedDistribution)
                                .reduce((a, b) => a + b)
                            )}
                          </b>
                        </td>
                      </tr>
                    </>
                  ) : (
                    <tr></tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>

        <div>
          <DistributionsNotIncludedTable
            forecastDistributions={forecastDistributions}
            forecastYear={forecastYear}
            changeTabs={props.changeTabs}
            isOnlyLoadingStatus={isOnlyLoadingStatus}
          />
        </div>

        <div>
          <p id="[1]">
            <dt>
              <span className="sr-only">Footnote&nbsp;</span>
            </dt>
            <dd>
              <sup>[1] </sup>
              Common assumption that should generally be used is 6%.
            </dd>
          </p>
          <p>
            Amounts shown here are based on the distribution election on file
            and are estimates only. This projection uses several simplifying
            assumptions to estimate future account balance earnings and the
            date(s) that benefit payments occur. This demonstration is
            hypothetical and your actual experience will differ from the
            assumptions used here.
          </p>
          <p>
            Please contact us at {employerContactPhoneNumber} if you would like
            to make a change to your election(s) for your unscheduled qualifying
            distribution event(s). Please note IRS rules regarding change of
            distribution elections must be followed and plan limitation may
            apply.
          </p>
          <p>
            The amounts above represent a measure of your estimated benefits
            under your plan and a promise by your employer to pay such benefit.
            Please note that your benefit is not guaranteed and your employer
            has not set aside assests for this payment.
          </p>
        </div>
      </div>
    </>
  );
}
