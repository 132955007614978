import React from "react";
import CustomDatePicker from "../../custom-date-picker/custom-date-picker";
import { RedeferralDateUtil } from "./redeferral-date-util";
import Date from "../../numbers/date";
import { userFacingDate } from "../../../util/date-util";

export interface Props {
  redeferralDateUtil: RedeferralDateUtil;
  isEnabled: boolean;
  onChange: (date: Date | null, e: React.PointerEvent | null) => void;
  editable: boolean;
}

export function RedeferralDateInput(props: Props) {
  const { onChange, redeferralDateUtil, isEnabled, editable } = props;

  if (
    !editable &&
    redeferralDateUtil.scheduledDistributionsForRedeferral.isRedeferred &&
    redeferralDateUtil.getDate
  ) {
    return (
      <Date date={redeferralDateUtil.getDate} formatter={userFacingDate} />
    );
  } else if (!editable) {
    return null;
  }

  if (!redeferralDateUtil.getDate) {
    return <>Change Pending Distribution</>;
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <CustomDatePicker
          name={`redeferral-${redeferralDateUtil.scheduledDistributionsForRedeferral.installNum}`}
          date={redeferralDateUtil.getDate}
          updateDate={onChange}
          hasError={false}
          title=""
          minDate={redeferralDateUtil.minDate}
          showYearPicker={redeferralDateUtil.showOnlyYearPicker}
          filterDates={redeferralDateUtil.filterDates}
          excludeDates={redeferralDateUtil.getMarketCloseDates}
          showMonthYearPicker={redeferralDateUtil.showOnlyMonthYearPicker}
          dateFormat={redeferralDateUtil.getDateFormat}
          includeDates={redeferralDateUtil.getIncludedDate}
          disabled={!isEnabled}
        />
      </div>
    </div>
  );
}
