import React from "react";
import "./account-filter.scss";
import { Account } from "../../../models/account";
import AccountName from "../../account-name/account-name";

type Props = {
  accounts: Account[];
  selectedAccounts: Account[];
  setSelectedAccounts: (accounts: Account[]) => void;
};

export default function AccountFilter(props: Props) {
  const { accounts, selectedAccounts, setSelectedAccounts } = props;

  const allAccountsSelected = accounts.length === selectedAccounts.length;

  function isChecked(account: Account) {
    return Boolean(
      selectedAccounts.find(
        (selectedAccount) => selectedAccount.id === account.id
      )
    );
  }

  function toggleAll(e: React.ChangeEvent<HTMLInputElement>) {
    const { checked } = e.target;
    if (checked) {
      setSelectedAccounts(accounts);
    } else {
      setSelectedAccounts([]);
    }
  }

  function handleChange(
    e: React.ChangeEvent<HTMLInputElement>,
    account: Account
  ) {
    const { checked } = e.target;
    if (checked) {
      setSelectedAccounts(addSelectedAccount(account));
    } else {
      const newAccounts = removeSelectedAccount(account);
      setSelectedAccounts(newAccounts);
    }
  }

  function removeSelectedAccount(account: Account) {
    return selectedAccounts.filter(
      (selectedAccount) => selectedAccount.id !== account.id
    );
  }

  function addSelectedAccount(account: Account) {
    return [...selectedAccounts, account];
  }

  return (
    <div className="account-filter">
      <h3>Change Accounts</h3>
      <div className="account-selection">
        <div className="styled-checkbox display-flex">
          <input
            className="custom-checkbox"
            type="checkbox"
            id="toggle-all"
            checked={allAccountsSelected}
            onChange={toggleAll}
            data-testid="toggle-all"
          />
          <label htmlFor="toggle-all" className="bold">
            {allAccountsSelected ? "Deselect All" : "Select All"}
          </label>
        </div>
      </div>
      {accounts.map((account, index) => (
        <div key={index}>
          <div className="styled-checkbox pad-left">
            <div className={"display-flex"}>
              <input
                className="custom-checkbox"
                type="checkbox"
                id={`checkbox-${index}`}
                name={`${account.id}`}
                checked={isChecked(account)}
                onChange={(e) => handleChange(e, account)}
                data-testid={`checkbox-${account.id}`}
              />
              <label htmlFor={`checkbox-${index}`}>
                <AccountName
                  name={account.name}
                  id={account.id}
                  asLink={false}
                />
              </label>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
